import React, { useContext } from 'react';
import PropTypes             from 'prop-types';
import { navigate }          from 'gatsby';
import {
  Spinner,
  Center,
  Flex,
  Box,
  Skeleton,
  Badge,
  Button,
  Alert,
  AlertIcon
}                            from '@chakra-ui/react';
import { Img }               from 'react-image';
import { useQuery }          from 'react-query';

import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';

import { endpoints }   from '../config';
import { IApiContext } from '../context/IApi';
import Error           from './Error';
import PriceTag        from './PriceTag';

const PropertyGrid = ({ status, limit, types }) => {
  const assetUrl = endpoints.api + '/assets/';
  const { fetchProperties } = useContext(IApiContext);
  const { isLoading, isError, data, error } = useQuery(['properties', { status, limit, types }], fetchProperties, {
    refetchOnWindowFocus: false,
    cacheTime: 'Infinity',
    staleTime: 'Infinity',
  });

  const onNavigate = (id) => {
    navigate(`/immobilien/${id}`);
  }

  return (
    <Wrapper>
      <Spacer/>
      {(isError || error) && <Error>Fehler beim Laden. Bitte versuchen Sie es später erneut.</Error>}
      {isLoading && <Center><Spinner/></Center>}
      {data && data.property.length === 0 && <><Alert status="info">
        <AlertIcon/>
        Keine Objekte gefunden. Versuchen Sie es später erneut.
      </Alert></>}
      {data && <>
        <Flex flexWrap="wrap" justifyContent="space-around">
          {data.property.map(property => <Box key={property.id} maxW="sm" borderWidth="1px" borderRadius="lg"
                                              overflow="hidden">
              <Box overflow="hidden" maxHeight="230px">
                {property.images.length > 0
                  ? <Img src={assetUrl + property.images[0].directus_files_id.id + '?key=overview-grid-preview'}
                         loader={<Skeleton height="100px"/>}/>
                  : <img src="https://placehold.jp/333/ffffff/400x230.png?text=Kein%20Bild"
                         alt="Kein Bild"/>}
              </Box>
              <Box p="6">
                <Box d="flex" alignItems="baseline">
                  <Badge borderRadius="full" px="2" colorScheme="brand">
                    {property.category.display_name}
                  </Badge>
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                    ml="2"
                  >
                    {property.rooms} Räume &bull; {property.living_space_qm} qm
                  </Box>
                </Box>
                <Box
                  mt="1"
                  fontWeight="semibold"
                  as="h4"
                  lineHeight="tight"
                  isTruncated
                >
                  {property.display_name}
                </Box>

                <Box>
                  <PriceTag price={property.purchase_price_brutto}/>
                </Box>
                <Center><Button onClick={() => onNavigate(property.id)} mt={4} colorScheme="brand">Mehr erfahren</Button></Center>
              </Box>
            </Box>
          )}
        </Flex>
      </>}
      <Spacer/>
    </Wrapper>
  )

};

PropertyGrid.propTypes = {
  status: PropTypes.string,
  limit: PropTypes.number,
  types: PropTypes.array
}

PropertyGrid.defaultProps = {
  status: 'published',
  limit: -1,
  types: ['sale', 'rent', 'project_development']
}

export default PropertyGrid;